<template>
    <b-row>
        <div class="d-flex align-items-center mb-1 ml-2">
            Exibir
            <b-form-select v-model="recordsPerPage" class="ml-1 mr-1 input-pdv-blue" size="sm">
                <option :value="10">10</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="75">75</option>
                <option :value="100">100</option>
            </b-form-select>
            {{ i18nRecords }}
        </div>
    </b-row>
</template>

<script>
export default {
    props: {
        perPage: {
            type: Number,
            required: false,
            default: 10,
        },
    },
    data() {
        return {
            recordsPerPage: this.perPage,
        }
    },
    computed: {
        i18nRecords() {
            return this.getI18nModified({
                    prefix: 'PAGINATION.records',
                    modifier: 2,
                })
        },
    },
    watch: {
        recordsPerPage(value) {
            this.$emit('changePerPage', value);
        },
    }
}
</script>
