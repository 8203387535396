<script>
import Vuetable from 'vuetable-2'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import RecordsPerPage from '@/src/components/RecordsPerPage.vue'

const i18nPagination = 'PAGINATION'

export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        RecordsPerPage,
    },
    props: {
        fetchData: {
            type: Function,
            required: true,
        },
        rowClass: {
            type: Function,
            required: false,
            default: () => {},
        },
        fields: {
            type: Array,
            required: true,
        },
        additionalParameters: {
            type: Object,
            required: false,
            default: () => {},
        },
        sortOrder: {
            type: Array,
            default: () => [],
        },
        perPage: {
            type: Number,
            required: false,
            default: 10,
        },
        displaySelectionRecordsPerPage: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            data: [],
            loading: true,
            parameters: this.additionalParameters,
            recordsPerPage: this.perPage,
            css: {
                table: {
                    tableClass: 'table table-centered table-hover',
                },
                pagination: {
                    activeClass: 'btn-primary text-white',
                    pageClass: 'btn',
                    linkClass: 'btn',
                    icons: {
                        prev: '',
                        next: '',
                    },
                },
            }
        }
    },
    watch: {
        perPage(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.refresh()
            }
        }
    },
    methods: {
        onLoaded: function () {
            this.loading = false
        },
        onLoading: function () {
            this.loading = true
        },
        checkboxToggled(payload, dataItem) {
            const eventName = payload
                ? 'checkbox-toggled'
                : 'checkbox-untoggled'
            this.$emit(eventName, dataItem)
        },
        rowClicked(data) {
            this.$emit('row-clicked', data)
        },
        reload() {
            this.$nextTick(() => {
                this.$refs.vuetable.reload()
            })
        },
        refresh() {
            this.$nextTick(() => {
                this.$refs.vuetable.refresh()
            })
        },

        i18nNoRecords() {
            return this.getI18n(i18nPagination, 'no_records')
        },

        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onRecordsPerPage(value) {
            this.recordsPerPage = value
        },

        onPaginationData(onPaginationData) {
            this.$refs.pagination.setPaginationData(onPaginationData)
            this.$refs.paginationInfo.setPaginationData(onPaginationData)
        },

        renderVuetable(h) {
            return h(
                'div',
                [
                    h('records-per-page', {
                        ref: 'recordsPerPage',
                        class: {
                            'col-md-12': true,
                        },
                        props: {
                            'perPage': this.perPage,
                        },
                        style: {
                            display: this.displaySelectionRecordsPerPage ? 'flex' : 'none !important',
                        },
                        on: {
                            'changePerPage': this.onRecordsPerPage,
                        }
                    }),
                    h('vuetable', {
                        ref: 'vuetable',
                        props: {
                            apiMode: true,
                            httpFetch: this.fetchData,
                            fields: this.fields,
                            sortOrder: this.sortOrder,
                            perPage: this.recordsPerPage,
                            appendParams: this.additionalParameters,
                            dataPath: 'data',
                            paginationPath: 'meta',
                            noDataTemplate: this.getI18n('PAGINATION', 'no_records_found'),
                            rowClass: this.rowClass,
                            css: this.css.table,
                        },
                        on: {
                            'vuetable:loading': this.onLoading,
                            'vuetable:loaded': this.onLoaded,
                            'vuetable:pagination-data': this.onPaginationData,
                            'vuetable:checkbox-toggled-custom': this.checkboxToggled,
                            'vuetable:row-clicked': this.rowClicked,
                        },
                        scopedSlots: this.$vnode.data.scopedSlots,
                    })
                ]);
        },

        renderPagination(h) {
            return h(
                'div',
                {
                    class: {
                        row: true,
                    },
                    style: {
                        visibility: this.loading ? 'hidden' : 'visible',
                    }
                },
                [
                    h('vuetable-pagination-info', {
                        ref: 'paginationInfo',
                        class: {
                            'col-md-5': true,
                        },
                        props: {
                            'no-data-template': '',
                            'info-template': `${this.getI18n(
                                i18nPagination,
                                'showing'
                            )} {from} ${this.getI18n(
                                i18nPagination,
                                'to'
                            )} {to} ${this.getI18nModified({
                                prefix: i18nPagination,
                                suffix: 'records',
                                modifier: 2,
                            })} ${this.getI18n(
                                i18nPagination,
                                'of'
                            )} {total} ${this.getI18nModified({
                                prefix: i18nPagination,
                                suffix: 'records',
                                modifier: 2,
                            })}`,
                        },
                    }),
                    h('vuetable-pagination', {
                        props: {
                            css: this.css.pagination,
                        },
                        class: {
                            'col-md-7': true,
                            pagination: true,
                            'justify-content-end': true,
                            ui: true,
                            right: true,
                            floated: true,
                            menu: true,
                        },
                        ref: 'pagination',
                        on: {
                            'vuetable-pagination:change-page': this
                                .onChangePage,
                        },
                    }),
                ]
            )
        },
    },

    render(h) {
        return h('div', [
            h('div', {
                class: {
                    'table-responsive': true
                },
                style: {
                    visibility: this.loading ? 'hidden' : 'visible',
                    height: this.loading ? '0' : '100%',
                },
            },
            [
                this.renderVuetable(h),
            ]),
            this.renderPagination(h),
            h('div', {
                style: {
                    visibility: this.loading ? 'visible' : 'hidden',
                    height: this.loading ? '100%' : '0',
                }
            }, [
                h('b-skeleton-table', {
                    props: {
                        'rows': 5,
                        'columns': this.fields ? this.fields.length : 5,
                        'table-props': { hover: true, stickyHeader: true }
                    },
                }),
            ]),
        ])
    },
}
</script>
